<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/home-hero.jpg')"
      class="white--text"
      gradient="to right, rgba(5, 11, 31, .5), rgba(5, 11, 31, .5)"
      :contain="false"
    >
      <v-container class="fill-height px-4 py-4">
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="4"
            xl="5"
          >
            <v-responsive
              class="d-flex align-center"
              height="100%"
              max-width="700"
              width="100%"
            >
              <base-heading
                :size="$vuetify.breakpoint.xl ? 'display-3' : 'display-1'"
                :title="translations.title"
              />

              <base-body>
                <p class="font-medium">
                  {{ translations.description }}
                </p>
              </base-body>

              <div
                :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
                class="d-flex flex-wrap"
              >
                <base-btn @click="goToAbout">
                  {{ translations.buttonCta }}
                </base-btn>

                <span class="font-weight-bold ml-6 mr-4 my-4">{{ translations.or }}</span>

                <base-btn
                  :ripple="false"
                  class="pa-1"
                  height="auto"
                  text
                  @click="goToContact"
                >
                  {{ translations.buttonContact }}
                </base-btn>
              </div>
            </v-responsive>
          </v-col>
          <v-col
            cols="12"
            xl="6"
            md="8"
          >
            <home-card
              class="mb-2"
            >
              <v-responsive>
                <v-row
                  class="ma-0 justify-end flex-nowrap"
                >
                  <v-col
                    v-if="$vuetify.breakpoint.mdAndUp"
                    cols="12"
                    md="3"
                  >
                    <base-img
                      :src="getImgUrl(translations.homeDescriptionPhoto)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="9"
                    class="ma-0 pa-0"
                  >
                    <div
                      style="max-height: 200px"
                      class="ml-4"
                      v-html="documentToHtmlString(translations.homeDescriptionDescription)"
                    />
                  </v-col>
                </v-row>

                <base-btn
                  :ripple="false"
                  class="pa-1 float-right"
                  height="auto"
                  text
                  @click="goToAbout"
                >
                  {{ translations.buttonCta }}
                </base-btn>
              </v-responsive>
            </home-card>
            <home-card class="mb-2">
              <v-responsive>
                <v-row
                  class="ma-0 pb-4"
                  justify-md="space-around"
                >
                  <v-col
                    v-for="(feature, i) in features"
                    :key="i"
                    class="xs pa-0"
                    cols="12"
                    md="3"
                  >
                    <home-feature-card
                      align="center"
                      v-bind="feature"
                      color="primary"
                      class="align-center"
                    />
                  </v-col>
                </v-row>
                <v-row
                  class="ma-0 justify-end"
                >
                  <base-btn
                    :ripple="false"
                    class="pa-1"
                    height="auto"
                    text
                    @click="goToOffer"
                  >
                    {{ translations.seeFullOffer }}
                  </base-btn>
                </v-row>
              </v-responsive>
            </home-card>
            <home-card>
              <v-responsive>
                <v-row
                  class="ma-0 justify-start"
                >
                  <home-galery
                    :gallery-one="translations.realizationsGallery1"
                    :gallery-two="translations.realizationsGallery2"
                    :gallery-three="translations.realizationsGallery3"
                    :gallery-one-title="translations.realizationsGalleryTitle1"
                    :gallery-two-title="translations.realizationsGalleryTitle2"
                    :gallery-three-title="translations.realizationsGalleryTitle3"
                  />
                </v-row>
                <v-row
                  class="ma-0 justify-end"
                >
                  <base-btn
                    :ripple="false"
                    class="pa-1"
                    height="auto"
                    text
                    @click="goToRealizations"
                  >
                    {{ translations.seeMoreRealizations }}
                  </base-btn>
                </v-row>
              </v-responsive>
            </home-card>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  import { mapState } from 'vuex'
  import { STORE_NAMESPACE_HOME } from '@/views/home/Index.vue'
  import { PL, EN } from '@/constants/lang.js'
  import HomeCard from '../../components/base/HomeCard'
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
  import getImgUrl from '@/utils/getImgUrl.js'
  import HomeFeatureCard from '../../components/HomeFeatureCard'
  import HomeGalery from '../../components/HomeGalery'

  export default {
    name: 'SectionHomeHero',
    components: { HomeGalery, HomeFeatureCard, HomeCard },
    provide: {
      theme: { isDark: true },
    },
    computed: {
      ...mapState(STORE_NAMESPACE_HOME, [
        'translations',
      ]),
      features () {
        const homeFeatures = this.translations.homeFeatures
        const homeFeaturesArr = homeFeatures ? Object.keys(homeFeatures).map(key => homeFeatures[key]) : []
        return this.$vuetify.breakpoint.mdAndUp ? homeFeaturesArr.slice(0, 4) : homeFeaturesArr.slice(0, 3)
      },
      minHeight () {
        return this.$vuetify.breakpoint.mdAndUp ? `calc(100vh - ${this.$vuetify.application.top}px)` : 'auto'
      },
    },
    methods: {
      documentToHtmlString,
      getImgUrl,
      goToContact () {
        const name = this.$route.meta.lang === PL ? 'Contact-pl' : 'Contact-en'
        this.$router.push({ name })
      },
      goToAbout () {
        const name = this.$route.meta.lang === PL ? 'About-pl' : 'About-en'
        this.$router.push({ name })
      },
      goToOffer () {
        const name = this.$route.meta.lang === PL ? 'Offer-pl' : 'Offer-en'
        this.$router.push({ name })
      },
      goToRealizations () {
        const name = this.$route.meta.lang === PL ? 'Realizations-pl' : 'Realizations-en'
        this.$router.push({ name })
      },
    },

  }
</script>
